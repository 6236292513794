<template>
  <div class="leftbox">
    <h3>Time Table</h3>
    <label>Filter by name</label><span><input type="text" v-model="filter" placeholder="filter by name"></span>
    <div>
      <table aria-label="time table">
        <thead>
        <tr>
          <th>Station</th>
          <th>Arrival</th>
          <th>Depart.</th>
          <th class="number">SwitchOff</th>
          <th class="number">Begin</th>
          <th class="number">Center</th>
          <th class="number">End</th>
          <th class="number">SwitchOn</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="station in filteredStations" :key="station">
          <td :class="{station : station.scheduledStop}">
            {{ station.displayName }}
          </td>
          <td>
            {{ toTime(station.arrivalTime) }}
          </td>
          <td>
            {{ toTime(station.departureTime) }}
          </td>
          <td class="location">
            {{ station.switchOff ? station.switchOff.location : "" }}
          </td>
          <td class="location">
            {{ station.begin ? station.begin.location : "" }}
          </td>
          <td class="location">
            {{ station.location.location }}
          </td>
          <td class="location">
            {{ station.end ? station.end.location : "" }}
          </td>
          <td class="location">
            {{ station.switchOn ? station.switchOn.location : "" }}
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
</template>

<script>

export default {
  name: "TimeTable",
  props: [
    "stations"
  ],
  data() {
    return {
      filter: '',
    }
  },
  methods: {
    toTime(dateTime) {
      return dateTime ? dateTime.substr(11, 5) : "";
    },
  },
  computed: {
    filteredStations() {
      return this.stations.filter((station) => {
        return this.filter === "" || station.displayName.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
      });
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  padding: 5px;
}
th {
  text-align: left;
}
th.number {
  text-align: right;
}
td.station {
  font-weight: 800;
}
td.location {
  text-align: right;
}
.leftbox {
  overflow: hidden;
  height: 100%;
}
.leftbox div {
  overflow: auto;
  height: calc(100% - 1.25em);
}
</style>