<template>
  <div class="leftbox">
    <h3>Advice</h3>
    <div>
      <highcharts :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import {Chart} from 'highcharts-vue';

highchartsMore(Highcharts);

export default {
  name: 'app',
  components: {
    highcharts: Chart,
  },
  props: [
      "route"
  ],
  methods: {
    onAdvice(wholeAdvice) {
      const ADVICE_TYPE_COLOR = {
        SPEED_KEEPING: "#84C7FF",
        COASTING: "#C37FCD",
        BRAKE: "#51A6AD",
        TRACTION: "#4E7799",
        NO_ADVICE: "#808000",
      };

      const adviceChanges = wholeAdvice
          .map(function (a,i,arr) {a.adviceChanged = (i==0 || (a.adviceType != arr[i-1].adviceType)); return a})
          .filter(advice => advice.adviceChanged)
          .map(function (advice) {
            return {value: advice.location, color: ADVICE_TYPE_COLOR[advice.adviceType]}
          });
      const zones = [];
      for (let i = 0; i < adviceChanges.length - 1; i++) {
        zones.push({
          value: adviceChanges[i + 1].value,
          color: adviceChanges[i].color
        });
      }
      zones.push({color: adviceChanges[adviceChanges.length - 1].color})

      this.chartOptions.series = [];
      this.chartOptions.series.push({
        type: 'line',
        name: 'AdvisedSpeed',
        data: wholeAdvice.map(advice => [advice.location, advice.speed * 3.6]),
        zones: zones,
        zoneAxis: 'x',
        enableMouseTracking: false,
      });
      this.chartOptions.series.push({
        animation: false,
        type: 'bubble',
        name: "Advice",
        dataLabels: {
          enabled: true,
          color: "#333",
          backgroundColor: "#eee",
          borderColor: "#888",
          borderWidth: 1,
          shape: "circle",
          format: '{point.y:.0f}',
        },
        data: wholeAdvice.filter(advice => advice.adviceChanged).map(function (advice) {
          return {x: advice.location, y: advice.speed * 3.6};
        }),
        tooltip: {
          pointFormatter: function () {
            const advice = wholeAdvice.filter(a => a.location === this.x)[0];
            switch (advice.adviceType) {
              case "NO_ADVICE":
                return "No advice";
              case "SPEED_KEEPING":
                return "Keep speed " + (advice.speed * 3.6).toFixed(2) + " km/h";
              case "COASTING":
                return "Coasting";
              case "BRAKE":
                return "Brake #" + advice.edBrake + "/" + advice.pBrake;
              case "TRACTION":
                return "Traction #" + advice.traction;
            }
            return "Advice type: " + advice.adviceType;
          }
        }
      });
      this.chartOptions.series.push({
        type: 'line',
        name: 'ActualSpeed',
        data: [],
        color: "#eee",
        enableMouseTracking: false,
      });
      this.chartOptions.series.push({
        type: 'line',
        name: 'Gradient',
        data: this.route
            .filter(routeItem => routeItem.location >= wholeAdvice[0].location && routeItem.location <= wholeAdvice[wholeAdvice.length - 1].location)
            .filter((_element, index, array) => index % Math.round(array.length / 200) == 0 )
            .map(function (routeItem) {
              return {x: routeItem.location, y: routeItem.gradient};
            }),
        lineWidth: 1,
        color: "#24c",
        enableMouseTracking: false,
        yAxis: 1,
      });
      this.chartOptions.series.push({
        type: 'line',
        name: 'Altitude',
        data: this.route
            .filter(routeItem => routeItem.location >= wholeAdvice[0].location && routeItem.location <= wholeAdvice[wholeAdvice.length - 1].location)
            .filter((_element, index, array) => index % Math.round(array.length / 200) == 0 )
            .map(function (routeItem) {
              return {x: routeItem.location, y: routeItem.altitude};
            }),
        lineWidth: 1,
        color: "#111",
        enableMouseTracking: false,
        yAxis: 2,
      });
      this.scaleXAxis();
      this.chartOptions.yAxis[1].visible = true;
    },
    onSimulation(simulationInfo) {
      this.chartOptions.xAxis.plotLines = [{
        color: '#268',
        width: 1,
        value: simulationInfo.location
      }];
      if (this.chartOptions.series && this.chartOptions.series[2]) {
        this.chartOptions.series[2].data.push([simulationInfo.location, simulationInfo.velocity * 3.6]);
      }
    },
    cleanGraph() {
      this.chartOptions.series = [];
    },
    changeAdviceVisibleLength(showWhole) {
      this.showWholeAdvice = showWhole;
      this.scaleXAxis();
    },
    scaleXAxis() {
      if (!this.chartOptions.series[0].data) return;
      const firstLocation = this.chartOptions.series[0].data[0][0];
      this.chartOptions.xAxis = {
        min: firstLocation - 500,
        max: this.showWholeAdvice ? undefined : (firstLocation + 5000),
        visible: true,
        labels: {
          formatter: function () {
            return (this.value - firstLocation) / 1000 + " km";
          }
        },
        tickPositions: this.calculateTickPositions(),
      }
    },
    calculateTickPositions() {
      const firstLocation = this.chartOptions.series[0].data[0][0];
      const lastLocation = this.chartOptions.series[0].data[this.chartOptions.series[0].data.length - 1][0];
      const tickPositions = [];
      const tickInterval = this.showWholeAdvice ? (lastLocation-firstLocation > 10000 ? 5000 : 2000) : 1000
      for (let tickPosition = firstLocation; tickPosition < lastLocation; tickPosition += tickInterval) {
        tickPositions.push(tickPosition);
      }
      return tickPositions;
    }
  },
  data() {
    return {
      chartOptions: {
        accessibility: {
          enabled: false,
        },
        chart: {
          type: 'line',
          width: 290,
          height: 200,
          animation: false,
          backgroundColor: '#888'
        },
        credits: {
          enabled: false,
        },
        title: '',
        subtitle: '',
        legend: {
          enabled: false
        },
        yAxis: [{
          min: 0,
          visible: false,
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          }
        },
        {
          min: Math.min.apply(null, this.route.map(item => item.gradient)),
          max: Math.max.apply(null, this.route.map(item => item.gradient)),
          gridLineWidth: 0,
          visible: false,
          tickInterval: 0.01,
          labels: {
            enabled: true,
            x: -32,
          },
          title: {
            enabled: false,
          },
          opposite: true,
        },
        {
          min: Math.min.apply(null, this.route.map(item => item.altitude)),
          max: Math.max.apply(null, this.route.map(item => item.altitude)),
          gridLineWidth: 0,
          visible: false,
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
          opposite: true,
        }],
        xAxis: {
          visible: false,
        },
        series: [{}],
      },
      showWholeAdvice: false,
    }
  },
}
</script>
