<template>
  <div id="sim">
    <div id="left">
      <div id="controls">
        <SimulationControl v-if="initialized" ref="control" :route="this.route"
                           :simulation="this.simulation" :stations="this.stations"
                           @change-speed="setDefaultSpeed" @start-simulation="onStart"/>
        <TrainControl v-if="initialized" ref="trainControl" :changed-speed=this.actualSpeed
                      :simulation="this.simulation"/>
        <GpsControl ref="gpsControl"/>
      </div>
      <div id="settings">
        <Info ref="info" @change-speed="getSpeedTabChange"/>
        <Settings ref="settings" @show-route="showRoute" @only-stations="onlyStations" @show-whole-advice="showWholeAdvice"/>
        <AdviceGraph v-if="initialized" ref="adviceGraph" :route="this.route"/>
      </div>
      <div id="timetable">
        <TimeTable v-if="initialized" ref="timetable" :stations="stations"/>
      </div>
    </div>
    <div id="map">
      <MapContainer ref="map" @station-click="stationClick"/>
    </div>
  </div>
</template>

<script>
import MapContainer from "@/components/MapContainer";
import Info from "@/components/Info";
import SimulationControl from "@/components/SimulationControl";
import TrainControl from "@/components/TrainControl";
import GpsControl from "@/components/GpsControl";
import TimeTable from "@/components/TimeTable";
import Settings from "@/components/Settings";
import AdviceGraph from "@/components/AdviceGraph";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  name: 'Simulation',
  data() {
    return {
      initialized: false,
      stations: [],
      allStations: [],
      route: [],
      actualSpeed: 0,
    }
  },
  components: {
    TimeTable,
    GpsControl,
    TrainControl,
    SimulationControl,
    MapContainer,
    Info,
    Settings,
    AdviceGraph
  },
  methods: {
    stompConnected() {
      const locoId = this.$route.params.locoId;
      this.stompClient.subscribe("/simulations/" + locoId, this.onSimulation);
      this.stompClient.subscribe("/controls/" + locoId, this.onControl);
    },
    onSimulation(response) {
      const data = JSON.parse(response.body);
      switch (response.headers["MessageType"]) {
        case "GpsPosition":
          this.$refs.map.onPosition(data);
          break;
        case "LocoPosition":
          this.$refs.map.onLocoPosition(data);
          break;
        case "SimulationInfo":
          this.$refs.info.onSimulation(data);
          this.$refs.trainControl.keepDefaultSpeed((data.velocity * 3.6).toFixed(0));
          this.$refs.adviceGraph.onSimulation(data);
          break;
        case "Advice":
          this.$refs.adviceGraph.onAdvice(data.advisedRunProfile);
          break;
      }
    },
    onControl(response) {
      const data = JSON.parse(response.body);
      switch (response.headers["MessageType"]) {
        case "TrainControl":
          this.$refs.trainControl.applyControls(data);
          break;
        case "GpsSettings":
          this.$refs.gpsControl.applyControls(data);
          break;
        case "SimulationStatus":
          this.$refs.control.status = data;
          break;
      }
    },
    showRoute(show) {
      this.$refs.map.showRoute(show, this.route, this.stations);
    },
    onlyStations(show) {
      this.stations = this.allStations.filter(s => show ? s.scheduledStop : true);
      if (this.$refs.settings.showRoute) {
        this.$refs.map.showRoute(false, this.route, this.stations);
        this.$refs.map.showRoute(true, this.route, this.stations);
      }
    },
    showWholeAdvice(show) {
      this.$refs.adviceGraph.changeAdviceVisibleLength(show);
    },
    stationClick(station) {
      this.$refs.control.location = parseInt(station.get("location"));
    },
    getSpeedTabChange(speed) {
      this.actualSpeed = speed;
    },
    setDefaultSpeed(speed) {
      this.$refs.trainControl.setDefaultSpeed(speed);
    },
    onStart() {
      this.$refs.adviceGraph.cleanGraph();
    }
  },
  async beforeCreate() {
    await Promise.allSettled([
      fetch('/api/simulations/' + this.$route.params.locoId + '/stations')
          .then(response => response.json())
          .then(json => this.stations = this.allStations = json),
      fetch('/api/simulations/' + this.$route.params.locoId + '/route')
          .then(response => response.json())
          .then(route => this.route = route),
      fetch('/api/simulations/' + this.$route.params.locoId)
          .then(response => response.json())
          .then(json => this.simulation = json)
    ]).then(() => this.initialized = true);
  },
  mounted() {
    let socket = new SockJS('/das');
    this.stompClient = Stomp.over(socket, {debug: false});
    this.stompClient.connect({}, this.stompConnected);
  },
  unmounted() {
    this.stompClient.disconnect();
  }
}
</script>

<style>
#sim {
  width: 100%;
  height: 100%;
  background-color: #888;
}

#left {
  display: inline-grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: min-content;
  vertical-align: top;
  height: 100%;
  overflow: hidden;
}

#controls {
  grid-column: 1;
  grid-row: 1;
}

#settings {
  grid-column: 2;
  grid-row: 1;
}

#timetable {
  grid-column: 1 / 3;
  grid-row: 2;
  overflow: hidden;
}

#map {
  display: inline-block;
  width: calc(100% - 600px);
  height: 100%;
}
</style>
