<template>
  <div id="container">
    <Simulation />
  </div>
</template>

<script>
import Simulation from '@/components/Simulation'

export default {
  name: 'SimulationDetails',
  components: {
    Simulation,
  },
}
</script>

<style>
#container{
  width: 100%;
  display: contents;
}
</style>

