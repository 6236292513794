<template>
  <div id="map-root"></div>
</template>

<script>
import 'ol/ol.css'
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import LineString from "ol/geom/LineString";
import OSM from 'ol/source/OSM'
import {fromLonLat} from 'ol/proj'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import MapStyles from "@/common/MapStyles";
import MultiLineString from "ol/geom/MultiLineString";

export default {
  name: 'MapContainer',
  components: {},
  props: {
  },
  emits: [
      "station-click"
  ],
  data() {
    return {
      olMap: null,
      positionFeature: null,
      positionFeatureLoco: null,
      routeLayer: null,
      lastMapMovement: null,
      route: null,
    }
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      this.olMap = new Map({
        target: 'map-root',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          zoom: 17,
          center: fromLonLat([19.11, 47.418]),
        }),
      })
      this.positionFeature = new Feature();
      this.positionFeature.setStyle(MapStyles.position);
      this.positionFeatureLoco = new Feature();
      this.positionFeatureLoco.setStyle(MapStyles.locoPosition);

      this.olMap.on('pointerdrag', this.onDragEnd);
      this.olMap.on('click', this.onClick);

      this.olMap.addLayer(new VectorLayer({
        source: new VectorSource({
          features: [this.positionFeature],
        }),
        zIndex: 99,
      }));
      this.olMap.addLayer(new VectorLayer({
        source: new VectorSource({
          features: [this.positionFeatureLoco],
        }),
        zIndex: 99,
      }));
    },
    onPosition(coordinates) {
      if (coordinates) {
        const position = new Point([coordinates.longitude, coordinates.latitude]).transform('EPSG:4326', 'EPSG:3857');
        this.positionFeature.setGeometry(position);
      } else {
        this.positionFeature.setGeometry(null);
      }
    },
    onLocoPosition(coordinates) {
      if (coordinates) {
        const position = new Point([coordinates.longitude, coordinates.latitude]).transform('EPSG:4326', 'EPSG:3857');
        this.positionFeatureLoco.setGeometry(position);
        if (!this.lastMapMovement || new Date().getTime() - this.lastMapMovement.getTime() > 15000) {
          this.olMap.getView().setCenter(fromLonLat([coordinates.longitude, coordinates.latitude]));
        }
      } else {
        this.positionFeatureLoco.setGeometry(null);
      }
    },
    onDragEnd() {
      this.lastMapMovement = new Date();
    },
    onClick(evt) {
      this.olMap.forEachFeatureAtPixel(evt.pixel, this.stationClick);
    },
    stationClick(feature) {
      if (feature.get("type") === "station") {
        this.$emit("station-click", feature);
      }
    },
    showRoute(show, route, stations) {
      if (show) {
        this.routeLayer = new VectorLayer({
          map: this.olMap,
          source: new VectorSource(),
          zIndex: 10,
        });

        this.route = route;
        const routePoints = route.map(point => fromLonLat([point.longitude, point.latitude]));
        const routeFeature = new Feature({
          geometry: new LineString(routePoints),
        });
        routeFeature.setStyle(MapStyles.route);
        this.routeLayer.getSource().addFeature(routeFeature);
        if (!this.positionFeatureLoco.getGeometry()) {
          this.olMap.getView().fit(routeFeature.getGeometry());
        }

        const stationFeatures = stations.map(station => {
          const feature = new Feature({
            name: station.displayName,
            type: "station",
            location: station.location.location,
            geometry: new Point(
                fromLonLat([station.location.longitude, station.location.latitude])
            ),
          })
          feature.setStyle(MapStyles.station);
          return feature;
        });
        const stationLinePoints = [];
        let routeIndex = 0;
        for (let i = 0; i < stations.length; i++) {
          const station = stations[i];
          while (station.begin && this.route[routeIndex].location < station.begin.location) routeIndex++;
          const stationLine = [];
          while (station.end && this.route[routeIndex].location <= station.end.location) {
            stationLine.push(fromLonLat([route[routeIndex].longitude, route[routeIndex].latitude]));
            routeIndex++;
          }
          stationLinePoints.push(stationLine);
        }
        const stationLineFeature = new Feature({
          geometry: new MultiLineString(stationLinePoints),
        });
        stationLineFeature.setStyle(MapStyles.stationOnRoute);
        this.routeLayer.getSource().addFeature(stationLineFeature)
        this.routeLayer.getSource().addFeatures(stationFeatures);
      } else {
        this.routeLayer.setVisible(false);
        this.olMap.updateSize(); // this needs for the visibility change to work - https://gis.stackexchange.com/questions/254248
      }
    },
  }
}
</script>

<style>
#map-root {
  width: 100%;
  height: 100%;
}
</style>

