<template>
  <div class="leftbox">
    <h3>Info</h3>
    <div>
      <label>Time</label><span>{{ formattedTime }}</span>
    </div>
    <div>
      <label>Location</label><span>{{ location }} m</span>
    </div>
    <div>
      <label>Track ID</label><span>{{ trackId }}</span>
    </div>
    <div>
      <label>Station</label><span>{{ stationName }}</span>
    </div>
    <div>
      <label>CM:(int + fract) </label><span>{{ customerMeterInteger }} m + {{ customerMeterFractional }} m</span>
    </div>

    <div>
      <label>Velocity</label><span>{{ velocityInKmH }} km/h ({{ velocity }} m/s)</span>
    </div>
    <div>
      <label>Speed Limit</label><span>{{ speedLimitInKmH }} km/h </span>
    </div>
    <div>
      <label>Acceleration</label><span>{{ acceleration }} m/s²</span>
    </div>
    <div>
      <label>Consumption</label><span>{{ userReadableConsumption }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  components: {},
  data() {
    return {
      location: 0,
      velocity: 0,
      acceleration: 0,
      time: 0,
      consumption: 0,
      customerMeterFractional: 0,
      customerMeterInteger: 0,
      speedLimit: 0,
      stationName: "",
      trackId: "",
    }
  },
  computed: {
    formattedTime() {
      const hours = Math.floor(this.time / 3600);
      const minutes = Math.floor((this.time - 3600 * hours) / 60);
      const seconds = this.time - 3600 * hours - 60 * minutes;
      return hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    },
    velocityInKmH() {
      return (this.velocity * 3.6).toFixed(2);
    },
    speedLimitInKmH() {
      return (this.speedLimit * 3.6).toFixed(0);
    },
    userReadableConsumption() {
      const units = ['J', 'kJ', 'MJ', 'GJ', 'TJ'];
      let unit = 0;
      const sign = this.consumption < 0 ? '-' : '';
      let n = Math.abs(this.consumption);
      while (n >= 1000) {
        n /= 1000;
        unit++;
      }
      return (sign + n.toFixed(n === 0 ? 0 : 2 - Math.floor(Math.log10(n))) + ' ' + units[unit]);
    }
  },
  methods: {
    onSimulation(simulationInfo) {
      this.location = simulationInfo.location.toFixed(2);
      this.velocity = simulationInfo.velocity.toFixed(2);
      this.acceleration = simulationInfo.acceleration.toFixed(2);
      this.time = simulationInfo.time.toFixed(0);
      this.consumption = simulationInfo.consumption.toFixed(2);
      this.customerMeterFractional = simulationInfo.customerMeterFractional;
      this.customerMeterInteger = simulationInfo.customerMeterInteger * 100;
      this.speedLimit = simulationInfo.speedLimit;
      this.customerMeterInteger = simulationInfo.customerMeterInteger * 100;
      this.stationName = simulationInfo.stationName;
      this.trackId = simulationInfo.trackId;
    }
  },
}
</script>
