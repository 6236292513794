<template>
  <div class="leftbox">
    <h3>Gps controls</h3>
    <form>
      <div>
        <label for="enabled">Enabled: </label>
        <input id="enabled" v-model="enabled" name="enabled" type="checkbox" @change="changeGps"/>
        <br>
        <label for="offsetDistance">Offset</label>
        <input id="offsetDistance" v-model="offsetDistance" max="200" min="0" name="offsetDistance" type="number"
               @change="changeGps"/>
        m
        <input id="offsetAngle" v-model="offsetAngle" max="360" min="0" name="offsetAngle" type="number"
               @change="changeGps"/>
        °<br>
        <label for="noiseDistance">Noise</label>
        <input id="noiseDistance" v-model="noiseDistance" max="300" min="0" name="noiseDistance" type="range"
               @change="changeGps"/>
        {{ noiseDistance }}m
        <br>
        <label for="accuracy">Accuracy</label>
        <input id="accuracy" v-model="accuracy" max="1000" min="0" name="accuracy" type="range" @change="changeGps"/>
        {{ accuracy }}m
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'GpsControl',
  components: {},
  data() {
    return {
      enabled: true,
      noiseDistance: 0,
      offsetDistance: 0,
      offsetAngle: 0,
      accuracy: 0,
      eventSendingEnabled: true,
    }
  },
  beforeCreate() {
    fetch('/api/simulations/' + this.$route.params.locoId + "/gps")
        .then(response => response.json())
        .then(gpsSettings => {
          this.enabled = gpsSettings.enabled;
          this.noiseDistance = gpsSettings.noiseDistance;
          this.offsetDistance = gpsSettings.offsetDistance;
          this.offsetAngle = gpsSettings.offsetAngle;
          this.accuracy = gpsSettings.accuracy;
        });
  },
  methods: {
    changeGps() {
      if (this.eventSendingEnabled) {
        fetch('/api/simulations/' + this.$route.params.locoId + '/gps', {
          method: 'PUT',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            enabled: this.enabled,
            noiseDistance: this.noiseDistance,
            offsetDistance: this.offsetDistance,
            offsetAngle: this.offsetAngle,
            accuracy: this.accuracy,
          })
        })
            .then(result => result.json())
      }
    },
    applyControls(control) {
      this.eventSendingEnabled = false;
      this.enabled = control.enabled;
      this.noiseDistance = control.noiseDistance;
      this.offsetDistance = control.offsetDistance;
      this.offsetAngle = control.offsetAngle;
      this.accuracy = control.accuracy;
      this.eventSendingEnabled = true;
    }
  }
}
</script>
<style>
h3 {
  margin-top: 2px;
  margin-bottom: 5px;
}

#noiseDistance, #accuracy {
  width: 100px;
}

#offsetDistance, #offsetAngle {
  width: 45px;
}
</style>