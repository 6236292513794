import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/views/Home"
import SimulationList from "@/views/SimulationList";
import SimulationDetails from "@/views/SimulationDetails";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/simulations',
    name: 'SimulationList',
    component: SimulationList
  },
  {
    path: '/simulation/:locoId',
    name: 'SimulationDetails',
    component: SimulationDetails
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
