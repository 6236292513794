<template>
  <form>
    <div>
      <label>Speed offset +/-</label><input id="speedOffset" v-model="speedOffset" name="speedOffset" size="3" type="text" @change="adviceChange"/><br>

      <label for="dontWait">At station</label>
      <input id="dontWait" v-model="stationBehaviour" type="radio" value="DONT_WAIT" @change="adviceChange"> do not wait<br>
      <label for="waitByTimetable"></label>
      <input id="waitByTimetable" v-model="stationBehaviour" type="radio" value="WAIT_BY_TIMETABLE"
             @change="adviceChange"> wait by timetable<br>
      <label for="waitForFixedTime"></label>
      <input id="waitForFixedTime" v-model="stationBehaviour" type="radio" value="WAIT_FOR_FIXED_TIME"
             @change="adviceChange"> wait
      <input id="secondsAtStation" v-model="secondsAtStation" name="secondsAtStation" size="3" type="text"
             @change="adviceChange"/> s <br>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Advice',
  components: {},
  props: ["initialBehaviour", "initialWaitTime", "initialRule", "initialSpeedOffset"],
  data() {
    return {
      stationBehaviour: this.initialBehaviour,
      secondsAtStation: this.initialWaitTime,
      speedOffset: (this.initialSpeedOffset * 3.6).toFixed(0),
    }
  },
  methods: {
    adviceChange() {
      fetch('/api/simulations/' + this.$route.params.locoId + '/control', {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          mode: "FOLLOW_ADVICE",
          stationBehaviour: this.stationBehaviour,
          secondsAtStation: this.secondsAtStation,
          speedOffset: this.speedOffset / 3.6,
        }),
      })
          .then(result => result.json())
    }
  }
}
</script>