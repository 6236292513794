<template>
  <div class="leftbox">
    <h3>Simulation controls</h3>
    <div v-show="status==='STOPPED'">
      <label for="station">Station</label>
      <select v-model="location" name="station" id="station" @change="checkOffset()">
        <option value="0" selected="selected">Select a station</option>
        <option :key="s" v-for="s in stations" :value="s.location.location" :class="{station : s.scheduledStop}">
          {{ s.displayName }}
        </option>
      </select>
    </div>
    <div v-show="status==='STOPPED'">
      <label for="location">Location</label>
      <input type="text" id="location" v-model="location" size="7" @blur="checkOffset()">
      <input type="text" id="offset" v-model="offset" size="5" placeholder="offset" @blur="checkOffset()">
    </div>
    <div v-show="status==='STOPPED'">
      <label for="velocity">Velocity</label>
      <input id="velocity" v-model="velocity" size="8" type="text" @change="startVelocity">
      km/h
    </div>
    <div>
      <button @click="startSimulation()" :style="{background: 'green'}"
              v-show="status==='STOPPED'">start
      </button>
      <button @click="stopSimulation()" :style="{background: 'red'}"
              v-show="status==='RUNNING'">stop
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimulationControl',
  props: [
    "stations",
    "route",
    "simulation"
  ],
  data() {
    return {
      status: this.simulation.status,
      station: null,
      location: (this.route && this.route[0]) ? this.route[0].location : 0,
      offset: null,
      velocity: 0,
    }
  },
  methods: {
    startVelocity() {
      this.$emit('change-speed', this.velocity);
    },
    startSimulation() {
      this.$emit('start-simulation', this.velocity);
      const startLocation = parseInt(this.location) || 0;
      const startOffset = parseInt(this.offset) || 0;
      const startVelocity = parseInt(this.velocity) || 0;
      fetch('/api/simulations/' + this.$route.params.locoId + '/start', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          location: startLocation + startOffset,
          velocity: startVelocity / 3.6,
        }),
      })
          .then(response => response.json())
          .then(json => this.status = json.status);
    },
    stopSimulation() {
      this.$emit('stop-simulation', this.velocity);
      fetch('/api/simulations/' + this.$route.params.locoId + '/stop', {
        method: 'POST',
      })
          .then(response => response.json())
          .then(json => this.status = json.status);
    },
    checkOffset() {
      const startLocation = parseInt(this.location) || 0;
      const startOffset = parseInt(this.offset) || 0;
      const routeStartsAt = this.route[0].location;
      const routeEndsAt = this.route[this.route.length - 1].location;
      if ((startLocation + startOffset) < routeStartsAt) {
        this.location = routeStartsAt - this.offset;
      }
      if (startLocation + startOffset > routeEndsAt) {
        this.offset = routeEndsAt - startLocation;
      }
    },
  }
}
</script>

<style scoped>
select {
  width: 150px;
}
option.station {
  font-weight: 800;
}
</style>
