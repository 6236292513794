<template>
  <div class="container">
    <Simulations />
  </div>
</template>
<script>
import Simulations from '@/components/Simulations'

export default {
  name: 'SimulationList',
  components: {
    Simulations,
  },
}
</script>
