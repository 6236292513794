<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/simulations">Simulations</router-link>
  </div>
  <router-view/>
</template>

<style>
body{
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 3px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}

label {
  display: inline-block;
  margin-left: 5px;
  width: 120px;
}
input, select {
  margin-bottom: 10px;
}
button {
  margin-bottom: 5px;
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 3px 15px;
  border-radius: 3px;
}

</style>
