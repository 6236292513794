<template>
  <form>
    <div>
      <label for="speed">Speed (km/h)</label>
      <input id="speed" v-model="speed" min="0" name="speed" type="range" @change="speedChange"/>
      {{ speed }}
      <label for="keepSpeeding">Keep Limits</label>
      <input id="keepSpeeding" name="keepSpeeding" type="checkbox" v-model="keepSpeeding" @change="speedChange"/>

    </div>
  </form>
</template>

<script>
export default {
  name: 'ConstantSpeed',
  components: {},
  props: ["speedLimit", "initialSpeed", "initialKeepSpeeding"],
  data() {
    return {
      speed: (this.initialSpeed * 3.6).toFixed(0),
      keepSpeeding: this.initialKeepSpeeding,
    }
  },
  methods: {
    speedChange() {
      fetch('/api/simulations/' + this.$route.params.locoId + '/control', {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          mode: "CONSTANT_SPEED",
          targetSpeed: this.speed / 3.6,
          keepingSpeedLimit: this.keepSpeeding,
        }),
      })
          .then(result => result.json())
    }
  }
}
</script>