import {Circle, Fill, Stroke, Style, Text} from "ol/style";

export default {
    locoPosition: new Style({
        image: new Circle({
            radius: 6,
            stroke: new Stroke({
                color: '#3399CC',
                width: 3,
            }),
        }),
    }),
    position: new Style({
        image: new Circle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
        }),
    }),
    route: new Style({
        stroke: new Stroke({
            color: '#3399dd',
            width: 2,
        })
    }),
    stationOnRoute: new Style({
        stroke: new Stroke({
            color: '#dd6933',
            width: 4,
        })
    }),
    station(feature) {
        return new Style({
            image: new Circle({
                radius: 5,
                fill: new Fill({
                    color: '#e77948',
                }),
                stroke: new Stroke({
                    color: '#222',
                    width: 1,
                }),
            }),
            text: new Text({
                text: feature.get("name"),
                offsetY: -10,
                font: '13px Calibri,sans-serif',
                fill: new Fill({color: '#fff'}),
                stroke: new Stroke({
                    color: '#000', width: 2
                }),
            }),
        })
    }
}