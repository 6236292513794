<template>
  <h1>Simulations</h1>
  <span id="simToRun">
    <select v-model="simulationToCreate">
      <option v-if="runs.length > 0" disabled selected value="">Select a Run</option>
      <option :key="run" :value="run.locoId" v-for="run in runs" v-show="simulations.filter(s=>s.locoId===run.locoId).length===0">
        {{ run.headCodeShort }} - {{ run.locoId }}
      </option>
    </select>
  </span>
  <button @click="createSimulation(simulationToCreate)" :style="{background: 'green'}">create</button>

  <div :key="simulation" v-for="simulation in simulations">
    <router-link :to="{ name: 'SimulationDetails', params: { locoId: simulation }}">{{ simulation }}</router-link>
    <button :style="{background: 'red'}" @click="stopSimulation(simulation)">delete</button>
  </div>
</template>

<script>
export default {
  name: 'Simulations',
  data() {
    return {
      simulations: [],
      simulationToCreate: "",
      runs: [],
    }
  },
  components: {
  },
  methods: {
    stopSimulation(locoId) {
      fetch('/api/simulations/' + locoId, {
        method: 'DELETE',
      })
        .then(() => {
          this.simulations = this.simulations.filter( (sim) => sim !== locoId);
        })
    },
    createSimulation(locoId) {
      if (locoId === "")return;

      fetch('/api/simulations/' + locoId, {
        method: 'POST',
      })
        .then(response => {
          if (response.status === 200) {
            this.initData();
          } else {
            alert(response.statusText);
          }
        })
    },
    initData() {
      fetch('/api/simulations')
          .then(response => response.json())
          .then(simulations => this.simulations = simulations);
      fetch('/api/runs')
          .then(response => response.json())
          .then(runs => {
            this.runs = runs;
            if (this.runs.length === 0) {
              alert("There are no active runs")
            }
          });
      this.simulationToCreate = "";
    }
  },
  created() {
    this.initData();
  },
}
</script>

<style scoped>
#simToRun, div a {
  display: inline-block;
  width: 200px;
}
#simToRun select {
  width: 190px;
}
</style>