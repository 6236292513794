<template>
  <div class="leftbox">
    <h3>Settings</h3>
    <form>
      <div>
        <label for="showRoute">Show route: </label>
        <input id="showRoute" name="showRoute" type="checkbox" v-model="showRoute" @change="onShowRoute"/>
      </div>
      <div>
        <label for="onlyStations">Only stations: </label>
        <input id="onlyStations" name="onlyStations" type="checkbox" v-model="onlyStations" @change="onOnlyStations"/>
      </div>
      <div>
        <label for="showWholeAdvice">Whole advice: </label>
        <input id="showWholeAdvice" name="showWholeAdvice" type="checkbox" v-model="showWholeAdvice" @change="onShowWholeAdvice"/>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  components:{ },
  emits: [
    "show-route", "only-stations", "show-whole-advice"
  ],
  data() {
    return {
      showRoute: false,
      onlyStations: false,
      showWholeAdvice: false,
    }
  },
  methods: {
    onShowRoute() {
      this.$emit("show-route", this.showRoute);
    },
    onOnlyStations() {
      this.$emit("only-stations", this.onlyStations);
    },
    onShowWholeAdvice() {
      this.$emit("show-whole-advice", this.showWholeAdvice)
    }
  }
}
</script>
