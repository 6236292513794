<template>
  <div class="leftbox">
    <h3>Train controls</h3>
    <tabs ref="tabs" :options="{ useUrlFragment: false }" @changed="tabChanged">
      <tab name="Speed">
        <ConstantSpeed v-if="created" ref="constantSpeed" :initial-keep-speeding="keepingSpeedLimit"
                       :initial-speed="targetSpeed" :speedLimit="simulation.speedLimit"/>
      </tab>
      <tab name="Advice">
        <Advice v-if="created" ref="advice" :initial-behaviour="stationBehaviour"
                :initial-speed-offset="speedOffset" :initial-wait-time="secondsAtStation"/>
      </tab>
    </tabs>
    </div>
</template>

<script>
import ConstantSpeed from "@/components/ConstantSpeed";
import Advice from "@/components/Advice";

export default {
  name: 'TrainController',
  components: {
    ConstantSpeed,
    Advice,
  },
  props: [
    "changedSpeed",
    "simulation"
  ],
  data() {
    return {
      created: false,
      eventsSendingEnabled: true,
      targetSpeed: 0,
      keepingSpeedLimit: false,
      stationBehaviour: "DONT_WAIT",
      secondsAtStation: 30,
      speedOffset: 0,
    }
  },
  beforeCreate() {
    fetch('/api/simulations/' + this.$route.params.locoId + "/control")
        .then(response => response.json())
        .then(control => {
          if (control.mode === 'CONSTANT_SPEED') {
            this.targetSpeed = control.targetSpeed;
            this.keepingSpeedLimit = control.keepingSpeedLimit;
            this.$refs.tabs.selectTab('#speed');
          } else if (control.mode === 'FOLLOW_ADVICE') {
            this.stationBehaviour = control.stationBehaviour;
            this.secondsAtStation = control.secondsAtStation;
            this.speedOffset = control.speedOffset;
            this.$refs.tabs.selectTab('#advice');
          }
          this.created = true;
        });
  },
  methods: {
    tabChanged(selectedTab) {
      if (this.created && this.eventsSendingEnabled) {
        if (selectedTab.tab.name === "Speed") {
          this.$refs.constantSpeed.speedChange();
        } else if (selectedTab.tab.name === "Advice") {
          this.$refs.advice.adviceChange();
        }
      }
    },
    setDefaultSpeed(speed) {
      this.$refs.constantSpeed.speed = speed;
      this.$refs.constantSpeed.speedChange();
    },
    keepDefaultSpeed(speed) {
      if (this.$refs.tabs.activeTabHash !== '#speed') {
        this.$refs.constantSpeed.speed = speed;
      }
    },
    applyControls(control) {
      this.eventsSendingEnabled = false;
      if (control.mode === 'CONSTANT_SPEED') {
        this.$refs.constantSpeed.speed = (control.targetSpeed * 3.6).toFixed(0);
        this.$refs.constantSpeed.keepSpeeding = control.keepingSpeedLimit;
        this.$refs.tabs.selectTab('#speed');
      } else if (control.mode === 'FOLLOW_ADVICE') {
        this.$refs.advice.stationBehaviour = control.stationBehaviour;
        this.$refs.advice.secondsAtStation = control.secondsAtStation;
        this.$refs.advice.speedOffset = (control.speedOffset * 3.6).toFixed(0);
        this.$refs.tabs.selectTab('#advice');
      }
      this.eventsSendingEnabled = true;
    }
  }
}
</script>

<style>
.tabs-component-tabs {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #555;
}

.tabs-component-tabs > li {
  padding: 5px;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.tabs-component-tabs > li.is-active {
  font-weight: bold;
  border-bottom: 2px solid #555;
}

.tabs-component-tabs .tabs-component-panel {
  background-color: #555;
}

.tabs-component-tabs li {
  background-color: #ddd;
}

.tabs-component-tabs li.is-active {
  background-color: #555;
}

.tabs-component-tabs li a {
  text-decoration: none;
  color: #000;
}
</style>